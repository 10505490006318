import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  piece: {data: null, pending: true},
  onsite: {data: null, pending: true},
  outbounds: {data: null, pending: true},
  transfers: {data: null, pending: true},
};

const inboundPieceDetails = createSlice({
  name: 'inboundPieceDetails',
  initialState,
  reducers: {
    setPiece: (state, action) => {
      state.piece = {...state.piece, ...action.payload};
    },
    setOnsite: (state, action) => {
      state.onsite = {...state.onsite, ...action.payload};
    },
    setOutbounds: (state, action) => {
      state.outbounds = {...state.outbounds, ...action.payload};
    },
    setTransfers: (state, action) => {
      state.transfers = {...state.transfers, ...action.payload};
    },
    resetInitialState: (state, action) => {
      state.piece = initialState.piece;
      state.outbounds = initialState.outbounds;
    },
  },
});

export const inboundPieceDetailsActions = inboundPieceDetails.actions;

export default inboundPieceDetails.reducer;
