import React from 'react';
import PropTypes from 'prop-types';
import {Box, Paper, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

const Body = ({Icon, title, value}) => {
  return (
    <Paper elevation={3} sx={styles.cardPaper}>
      {Icon && <Icon sx={styles.itemIcon} />}
      <Box sx={{position: 'absolute'}}>
        <Typography sx={styles.cardTitle}>{title}</Typography>
        <Typography sx={styles.cardValue}>{value}</Typography>
      </Box>
    </Paper>
  );
};
Body.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const SummaryCard = (props) => {
  const Icon = props?.Icon || null;
  const link = props?.link || null;
  const title = props?.title || 'Missing';
  const value = props?.value !== undefined ? props.value : 'Missing';

  if (!link) return <Body Icon={Icon} title={title} value={value} />;
  return (
    <Link to={link}>
      <Body Icon={Icon} title={title} value={value} />
    </Link>
  );
};

const styles = {
  cardPaper: {
    'alignItems': 'center',
    'color': 'rgba(255,0,0,0.08)',
    'display': 'flex',
    'justifyContent': 'center',
    'minHeight': '90px',
    'padding': '8px',
    'transition': 'all 0.4s ease',
    ':hover': {
      'color': 'rgba(255,0,0,0.15)',
      '& .MuiTypography-root': {color: 'secondary.dark'},
    },
  },
  cardTitle: {
    color: 'primary.main',
    fontSize: '1.5rem',
    fontWeight: 200,
    lineHeight: 1,
    textAlign: 'center',
    transition: 'all 0.4s ease',
  },
  cardValue: {
    color: 'secondary.main',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: 1.5,
    textAlign: 'center',
    transition: 'all 0.4s ease',
  },
  itemIcon: {fontSize: '4rem', margin: '8px'},
};
SummaryCard.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
export default React.memo(SummaryCard);
