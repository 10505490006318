import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {materialsActions} from 'state/admin/slices/materials';
const snapOptions = {includeMetadataChanges: true};

const useAdminMaterials = () => {
  const {all, active, pending, touched, map, mixAllowed} = useSelector(state => state.admin.materials);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (touched) return;
    dispatch(materialsActions.setTouched(true));

    let unsubscribe = null;
    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state and unsubscribe to listener
      if (!user) {
        dispatch(materialsActions.forgetState());
        if (unsubscribe) unsubscribe();
      }

      // USER IS AUTHENTICATED
      // When user is authenticated, create snapshot listener and add data results to redux state
      const query = Firestore.collection('Admin-Materials');
      const snapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const all = [];
        const active = [];
        const profiled = [];
        const map = {};
        const mixAllowed = [];

        snap.forEach(doc => {
          const docData = doc.data();
          const material = {
            ...docData,
            DocId: doc.id,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          all.push(material);
          map[doc.id] = material;
          if (docData.Active) active.push(material);
          if (docData?.Profile) profiled.push(material);
          if (docData?.MixAllowed) mixAllowed.push(material);
        });

        dispatch(materialsActions.setAll(all));
        dispatch(materialsActions.setActive(active));
        dispatch(materialsActions.setProfiled(profiled));
        dispatch(materialsActions.setMixAllowed(mixAllowed));
        dispatch(materialsActions.setMap(map));
        dispatch(materialsActions.setPending(false));
      };
      const snapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAdminMaterials Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, snapOptions, snapData, snapError);
    });
  }, [dispatch, touched]);

  return {all, active, pending, touched, map, mixAllowed};
};

export default useAdminMaterials;
