import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {facilityUnitsActions} from 'state/admin/slices/facilityUnits';
const snapOptions = {includeMetadataChanges: true};

const useAdminFacilityUnits = () => {
  const {all, active, pending, touched} = useSelector(state => state.admin.facilityUnits);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (touched) return;
    dispatch(facilityUnitsActions.setTouched(true));

    let unsubscribe = null;
    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state and unsubscribe to listener
      if (!user) {
        dispatch(facilityUnitsActions.forgetState());
        if (unsubscribe) unsubscribe();
      }

      // USER IS AUTHENTICATED
      // When user is authenticated, create snapshot listener and add data results to redux state
      const query = Firestore.collection('Admin-Facility-Units');
      const snapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const all = [];
        const active = [];
        const map = {};

        snap.forEach(doc => {
          const docData = doc.data();
          const facilityUnit = {
            ...docData,
            DocId: doc.id,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          all.push(facilityUnit);
          if (docData.Active) active.push(facilityUnit);
          map[doc.id] = facilityUnit;
        });

        dispatch(facilityUnitsActions.setAll(all));
        dispatch(facilityUnitsActions.setActive(active));
        dispatch(facilityUnitsActions.setMap(map));
        dispatch(facilityUnitsActions.setPending(false));
      };
      const snapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAdminFacilityUnits Error: ', error.message, {error});
      };
      unsubscribe = Firestore.onSnapshot(query, snapOptions, snapData, snapError);
    });
  }, [dispatch, touched]);

  return {all, active, pending, touched};
};

export default useAdminFacilityUnits;
